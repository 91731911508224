<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="900"
    :visible="visible"
    :confirm-loading="loading"
    title="新建资产信息"
    :body-style="{ paddingBottom: '8px', paddingTop: '20px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      layout="vertical"
    >
      <a-row :gutter="16">
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="所属单位" name="affiliatedUnit">
            <a-tree-select
              allow-clear
              tree-default-expand-all
              placeholder="请选择所属单位"
              v-model:value="form.affiliatedUnit"
              :tree-data="organizationList"
              :replaceFields="{
                children: 'children',
                title: 'organizationName',
                key: 'organizationId',
                value: 'organizationId'
              }"
              :dropdown-style="{ maxHeight: '360px', overflow: 'auto' }"
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="入库日期" name="storageDate">
            <a-date-picker
              class="disabledColor ud-fluid"
              value-format="YYYY-MM-DD"
              placeholder="请输入库日期"
              v-model:value="form.storageDate"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="公物仓" name="warehouseId">
            <a-select
              allow-clear
              placeholder="请选择公物仓"
              v-model:value="form.warehouseId"
            >
              <a-select-option
                v-for="item in warehouseList"
                :key="item.warehouseId"
                :value="item.warehouseId"
              >
                {{ item.unitName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="资产编号" name="assetsCode">
            <a-input
              v-model:value="form.assetsCode"
              placeholder="自动生成"
              allow-clear
              :disabled="true"
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="资产名称" name="assetsName">
            <a-input
              v-model:value="form.assetsName"
              placeholder="请输入资产名称"
              allow-clear
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="资产分类代码" name="assetsSortCode">
            <a-tree-select
              allow-clear
              placeholder="请选择父代码"
              v-model:value="form.categoryCode"
              :tree-data="categoryData"
              :replaceFields="{
                children: 'children',
                title: 'categoryName',
                value: 'categoryCode',
                key: 'categoryCode'
              }"
              :dropdown-style="{ maxHeight: '360px', overflow: 'auto' }"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="品牌" name="brand">
            <a-input
              v-model:value="form.brand"
              placeholder="请输入品牌"
              allow-clear
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="型号规格" name="model">
            <a-input
              v-model:value="form.model"
              placeholder="请输入型号规格"
              allow-clear
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="取得日期" name="buyDate">
            <a-date-picker
              class="disabledColor ud-fluid"
              value-format="YYYY-MM-DD"
              placeholder="请选择取得日期"
              v-model:value="form.buyDate"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="数量" name="count">
            <a-input
              v-model:value="form.count"
              placeholder="请输入数量"
              allow-clear
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="单价（元）" name="unitPrice">
            <a-input
              v-model:value="form.unitPrice"
              placeholder="请输入单价"
              allow-clear
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="金额（元）" name="total">
            <a-input
              v-model:value="form.total"
              placeholder="请输入金额"
              allow-clear
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="现状">
            <a-select
              v-model:value="form.status"
              placeholder="请选择现状"
              allow-clear
              class="disabledColor"
            >
              <a-select-option
                v-for="item in statusList"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="备注" name="remark">
            <a-input
              v-model:value="form.remark"
              placeholder="请输入备注"
              allow-clear
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="资产图片" name="assetsPhoto">
            <a-upload
              list-type="picture-card"
              v-model:file-list="pointPhotoList"
              @preview="handlePreview"
              :customRequest="({ file }) => uploadFile(file, 'assetsPhoto')"
            >
              <div v-if="pointPhotoList.length < 1">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as assetsInfoApi from '@/api/ams/assets'
import setting from '@/config/setting'
import { PlusOutlined } from '@ant-design/icons-vue'

export default {
  name: 'assetsAdd',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 全部机构
    organizationList: Array,
    organizationAll: Array,
    categoryData: Array,
    assetsStateList: Array,
    statusList: Array,
    warehouseList: Array
  },
  components: {
    PlusOutlined
  },
  data() {
    return {
      active: 'info',
      disabled: false,
      uploadUrl: setting.uploadUrl,
      // 表单数据
      form: Object.assign({}, this.data),
      // 编辑弹窗表单验证规则
      rules: {
        batteryCode: [
          {
            required: true,
            message: '请输入电池编号',
            type: 'string',
            trigger: 'blur'
          }
        ],
        brandName: [
          {
            required: true,
            message: '请输入品牌',
            type: 'string',
            trigger: 'blur'
          }
        ],
        deviceCode: [
          {
            required: true,
            message: '请输入设备编号',
            type: 'string',
            trigger: 'blur'
          }
        ],
        modle: [
          {
            required: true,
            message: '请输入型号',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      pointPhotoList: [],
      previewImage: '',
      previewVisible: false,
      departmentList: [],
      userList: [],
      departmentList2: [],
      userList2: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        if (this.form.categoryFullCode) {
          this.form.categoryCodes = this.data.categoryFullCode.split(',')
        }
        if (this.form.assetsImg) {
          this.pointPhotoList = [{ url: this.form.assetsImg }]
        }
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.form.categoryCodes) {
            this.form.categoryCode =
              this.form.categoryCodes[this.form.categoryCodes.length - 1]
            this.form.categoryFullCode = this.form.categoryCodes.join(',')
          }
          if (this.form.assetsPhoto) {
            this.form.assetsImg = this.form.assetsPhoto
          }
          assetsInfoApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    async handlePreview(pointPhoto) {
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'assetsPhoto') {
              this.pointPhotoList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            }
            this.form[name] = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    }
  }
}
</script>

<style scoped lang='less'>
.form {
  :deep(.disabledColor) {
    background: #fff;
    opacity: 1;
    color: black;
    input[disabled],
    select[disabled] {
      color: black;
    }
    .ant-input-disabled,
    .ant-select-selector {
      background: #fff;
      color: black;
    }
  }
}
</style>
